import Vue from "vue";
import Vuex from "vuex";
import { processAlert, decodeJWT } from "../plugins/utils";
import { xbrlDataStore } from "./xbrlDataStore";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        globalMessage: {},
        pageMenu: [],
        validationErrors: {
            required: "This is a required field",
            email: "Invalid e-mail address",
        },
        currentPage: 1,
        apiToken: null,
        scrollOffsetTop: 0,
        loggedIn: false,
        userRole: null,
        userName: null,
        cache:{
            enums: {},
        },
        currentCompany: null,
    },
    mutations: {
        setGlobalMessage: function(state, message) {
            state.globalMessage = processAlert(message);
        },
        setPageMenu: function(state, menu) {
            state.pageMenu = menu;
        },
        setCurrentPage(state, currentPage) {
            state.currentPage = currentPage;
        },
        SET_apiToken(state, payload) {
            state.apiToken = payload;
            let token = decodeJWT(payload);
            state.userName = token.name;
            state.userRole = token.role;
            state.loggedIn = true;
            state.loggedInTs = new Date().getTime();
            localStorage.setItem('expiring', token.exp);
            localStorage.setItem('apitoken', JSON.stringify(payload));
        },
        SET_refreshToken(state, payload) {
            state.refreshToken = payload;
            localStorage.setItem('refreshtoken', payload);
        },
        REMOVE_apiToken(state) {
            state.apiToken = null;
            state.userName = null;
            state.userRole = null;
            state.loggedIn = false;
            localStorage.removeItem('apitoken');
            localStorage.removeItem('refreshtoken');
            localStorage.removeItem('expiring');

        },
        SET_scrollOffsetTop(state, payload) {
            state.scrollOffsetTop = payload;
        },
        SET_enums(state, payload){
            state.cache.enums = payload
        },
        SET_currentCompany(state, payload){
            state.currentCompany = payload;
        }
    },
    modules: {
        xbrlData: xbrlDataStore,
    },
});
